/* eslint-disable no-unused-vars */
import { gptResponse } from "./store";

async function askChatGPT(question, context = "") {
  try {
    const response = await fetch(
      "https://askgpt-excel.points-unknown.workers.dev/?token=05EScvWT6crQUDUDFwHMiwK8BpLRrrYGuccSuFDIvzr6IAbg",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: `
You are an excel expert, providing anlysis and review of spreadsheet information or coaching a user on how to do tasks in excel.
Users will ask you questions and you will provide a response that is clear and concise, and easy to understand.
The user may provide additional context, in the form of cell contents from their excel workbook, to help you answer their question. 
You may be asked to analyze this data and provide review or insights into the contextual data. Or you may be asked for advice or instructions.
You should always provide a response that is relevant to the question asked and the context provided.
\n\n
It is okay for you to be brief. You can offer short explanations, but don't go into too much detail.
You do not need to provide examples unless specifically asked in the question.
If the user provides context, you should investigate this in detail, evaluating the formulas and functions used. 
\n\n
Please provide your response in markdown format. Use code blocks for any formulas or functions you mention. Use bold text for any 
important points you want to make. Use bullet points for lists. Use links for any relevant documentation or resources. 
If you do not have a high degree of certainty in the answer, it is okay to say "I don't know".
\n\n
This is the question:\n
${question}
\n\n
The user provided the following context:\n
${context}`,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error; // Re-throw the error so it can be handled by the caller
  }
}

export async function onAskQuestionButtonClick(question, includeContext) {
  gptResponse.loading = true;
  try {
    await window.Excel.run(async (context) => {
      let contextText = "";

      if (includeContext) {
        const selectedRange = context.workbook.getSelectedRange();
        selectedRange.load("formulas");
        selectedRange.load("values");
        selectedRange.load("address");
        selectedRange.load("rowCount");
        selectedRange.load("columnCount");
        selectedRange.load("worksheet");

        await context.sync();

        // Join all formulas and values into a single string
        contextText = `The selected range is: ${selectedRange.address} \n
        with ${selectedRange.rowCount} rows and ${
          selectedRange.columnCount
        } columns.\n
        The formulas are:\n
         ${selectedRange.formulas.flat().join(" ")}. 
        The values are: \n
         ${selectedRange.values.flat().join(" ")}.`;

        //await context.sync();
      }

      const answer = await askChatGPT(question, contextText);
      await context.sync();
      gptResponse.value = answer;
      gptResponse.loading = false;
      return answer;
    });
  } catch (error) {
    console.error(error);
  }
}

export async function insertFormula(formula) {
  try {
    // insert the formula into the selected cell in Excel
    await window.Excel.run(async (context) => {
      const range = context.workbook.getSelectedRange();
      range.formulas = [[formula]];
      await context.sync();
      return range;
    });
  } catch (error) {
    console.error(error);
    return null;
  }
}

/*
  //****** BELOW IS THE CODE FOR THE CLOUDFLARE WORKER
  //THAT SHOULD BE USED TO KEEP THE CHATGPT API KEY SECRET

addEventListener('fetch', event => {
  event.respondWith(handleRequest(event.request))
})

async function handleRequest(request) {
  const url = new URL(request.url);
  const token = url.searchParams.get('token');

  if (!token || token !== 'YOUR_SECRET_TOKEN') {
    return new Response('Unauthorized', { status: 401 });
  }

  const { question } = await request.json();

  const response = await fetch('YOUR_CHATGPT_API_ENDPOINT', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer YOUR_API_KEY'
    },
    body: JSON.stringify({
      prompt: question,
      max_tokens: 250
    })
  });

  const data = await response.json();
  return new Response(JSON.stringify(data.choices[0].text.trim()), {
    headers: { 'Content-Type': 'application/json' }
  });
}

  */
