async function saveWorkbook() {
  try {
    await window.Excel.run(async (context) => {
      // Get the current workbook
      const workbook = context.workbook;

      // Save the workbook
      workbook.save();

      await context.sync();
    });
  } catch (error) {
    console.error("Error saving workbook:", error);
  }
}

export default saveWorkbook;
