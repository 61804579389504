import axios from "axios";

async function uploadFile(file, fullURL, output, fileType) {
  output.responded = true;
  output.response += "Beginning upload to Azure...\n";
  if (!file || file.type != fileType) {
    console.error("File is undefined, null, or not a PDF.");
    output.response = "File is undefined, null, or not a PDF.\n";
    return;
  }

  const formData = new FormData();
  formData.append("pdfFile", file);

  var data = null;
  // Set up axios request with timeout
  const axiosConfig = {
    headers: {
      "x-functions-key":
        "xB48OvWuxIhvSEy9agrHG-cAiRZmEQwUyNW2PKMd4lDFAzFua02C9A==",
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    timeout: 600000, // 600 seconds timeout
  };
  try {
    const response = await axios.post(fullURL, formData, axiosConfig);
    data = response.data;
    output.response += "Successfully uploaded...\n";
    return data;
  } catch (error) {
    if (error.response) {
      console.error(
        "Server error:",
        error.response.status,
        error.response.data
      );
    } else if (error.request) {
      console.error("Network error - no response received:", error.request);
    } else {
      console.error("Error:", error.message);
    }
  }
}

export { uploadFile };
