//Check excel file for existing workbook names

async function getUniqueWorksheetName(startingName) {
  let sheets;
  await window.Excel.run(async (context) => {
    try {
      sheets = context.workbook.worksheets;
      sheets.load("items/name");
      await context.sync();
    } catch (error) {
      console.error("Error checking existing workbook names:", error);
      return false;
    }
  });
  let sheetNames = [];
  sheets.items.forEach(function (sheet) {
    sheetNames.push(sheet.name);
  });
  let counter = 1;
  let uniqueName = `${startingName}-${counter}`;

  while (sheetNames.includes(uniqueName)) {
    counter++;
    uniqueName = `${startingName}-${counter}`;
  }
  return uniqueName;
}

function processTableData(table, options = {}) {
  const {
    keepCheckmarks = false,
    selectionMarkFormat = "checkbox",
    customSelected = "☒",
    customUnselected = "☐",
  } = options;

  function formatSelectionMark(content) {
    if (!content.includes(":selected:") && !content.includes(":unselected:")) {
      return content;
    }

    if (!keepCheckmarks) {
      return "";
    }

    const isSelected = content.includes(":selected:");

    switch (selectionMarkFormat) {
      case "checkbox":
        return isSelected ? customSelected : customUnselected;
      case "boolean":
        return isSelected ? "Yes" : "No";
      case "text":
        return isSelected ? "Selected" : "Not Selected";
      default:
        return content;
    }
  }

  let dataRows = [];
  let currentRow = {};
  let lastRowIndex = -1;

  table.cells.forEach((cell) => {
    if (lastRowIndex !== cell.rowIndex) {
      if (
        lastRowIndex !== -1 &&
        Object.values(currentRow).some((value) => value !== "")
      ) {
        dataRows.push(currentRow);
      }
      currentRow = {};
      lastRowIndex = cell.rowIndex;
    }

    const formattedContent = formatSelectionMark(cell.content);
    if (keepCheckmarks || formattedContent !== "") {
      currentRow[`Column${cell.columnIndex}`] = formattedContent;
    }
  });

  if (Object.values(currentRow).some((value) => value !== "")) {
    dataRows.push(currentRow);
  }

  return dataRows;
}

async function createGenericWorksheetFromData(data, options = {}) {
  const {
    name = "AiOCR-Data",
    headers = true,
    headerRowCount = 1,
    freezeHeaderRows = false,
    autoFitColumns = true,
    headerColor1 = "#D3D3D3",
    headerColor2 = "#E8E8E8",
    detectMergedHeaders = true,
  } = options;

  var worksheetName = await getUniqueWorksheetName(name);

  if (!data || data.length < headerRowCount) {
    console.error("Not enough data to process");
    return;
  }

  try {
    await window.Excel.run(async (context) => {
      // Add a new worksheet
      let sheet = context.workbook.worksheets.add(worksheetName);
      sheet.activate();

      // Get all possible columns from the data
      const allColumns = new Set();
      data.forEach((row) => {
        Object.keys(row).forEach((key) => allColumns.add(key));
      });
      const columnKeys = Array.from(allColumns).sort();

      // Extract header rows
      const headerRows = data.slice(0, headerRowCount);
      const dataRows = data.slice(headerRowCount);

      // Convert data to 2D array for Excel
      const excelRows = [];

      // Process header rows
      for (let i = 0; i < headerRowCount; i++) {
        const headerRow = [];
        columnKeys.forEach((key) => {
          headerRow.push(headerRows[i][key] || "");
        });
        excelRows.push(headerRow);
      }

      // Process data rows
      dataRows.forEach((row) => {
        const dataRow = [];
        columnKeys.forEach((key) => {
          dataRow.push(row[key] || "");
        });
        excelRows.push(dataRow);
      });

      // Write to Excel
      const range = sheet.getRangeByIndexes(
        0,
        0,
        excelRows.length,
        columnKeys.length
      );
      range.values = excelRows;

      // Format headers
      if (headers) {
        for (let i = 0; i < headerRowCount; i++) {
          const headerRange = sheet.getRangeByIndexes(
            i,
            0,
            1,
            columnKeys.length
          );
          headerRange.format.font.bold = true;
          headerRange.format.fill.color = i === 0 ? headerColor1 : headerColor2;
        }
      }

      // Detect and merge header cells if needed
      if (detectMergedHeaders && headers) {
        // Process first header row to find potential merged cells
        const headerRow = excelRows[0];
        let startIndex = 0;

        while (startIndex < headerRow.length) {
          if (!headerRow[startIndex]) {
            startIndex++;
            continue;
          }

          // Find consecutive cells with same value or where 2nd+ cells are empty
          let endIndex = startIndex + 1;
          while (
            endIndex < headerRow.length &&
            (headerRow[endIndex] === headerRow[startIndex] ||
              headerRow[endIndex] === "")
          ) {
            endIndex++;
          }

          // If we found multiple cells, merge them
          if (endIndex > startIndex + 1) {
            const mergeRange = sheet.getRangeByIndexes(
              0,
              startIndex,
              1,
              endIndex - startIndex
            );
            mergeRange.merge();
            mergeRange.format.horizontalAlignment = "Center";
          }

          startIndex = endIndex;
        }
      }

      // AutoFit columns if requested
      if (autoFitColumns) {
        range.format.autofitColumns();
      }

      // Add borders
      range.format.borders.getItem("InsideHorizontal").style = "Continuous";
      range.format.borders.getItem("InsideVertical").style = "Continuous";
      range.format.borders.getItem("EdgeBottom").style = "Continuous";
      range.format.borders.getItem("EdgeTop").style = "Continuous";
      range.format.borders.getItem("EdgeLeft").style = "Continuous";
      range.format.borders.getItem("EdgeRight").style = "Continuous";

      // Freeze the header rows if requested
      if (freezeHeaderRows && headers) {
        sheet.freezePanes.freezeRows(headerRowCount);
      }

      await context.sync();
    });
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export { processTableData, createGenericWorksheetFromData };
