<template>
    <div class="main m-1 text-center text-100 text-xl">
        <h1>Ask ChatGPT</h1>
        <div class="field grid text-base p-1 align-items-center">

            <div class="grid">
                <div class="col-12 align-items-center pb-3">
                    <div class="pb-2">Type Your Query for ChatGPT:</div>
                    <Textarea v-model="question" placeholder="Type your question here" id="questionInput" class="w-full"
                        rows="5" /><br />
                </div>
                <div class="col-6 align-items-right text-right p-y1">
                    Include highlighted <br />cells as context?
                </div>
                <div class="col-6 align-items-center text-left vertical-align-middle	p-y1">
                    <Checkbox v-model="includeContext" id="includeContext" binary variant="filled" />
                </div>
                <div class="col-12 align-items-center py-3">
                    <Message severity="warn" :closable="true">
                        <strong>This feature queries ChatGPT/OpenAI. Do not include proprietary information.</strong>
                    </Message>
                </div>
                <Button :label="isLoading ? 'Asking...' : '*** Ask ***'"
                    :icon="gptResponse.loading ? 'pi pi-spin pi-spinner' : 'pi pi-send'"
                    @click="onAskQuestionButtonClick(question, includeContext)" id="askButton" class="w-full"
                    :loading="gptResponse.loading" :disabled="gptResponse.loading" raised />
                <div v-if="gptResponse.value !== ''" class="pt-3">
                    <Card class="custom-card">
                        <template #title>GPT Response</template>
                        <template #content>
                            <div v-html="renderedHtml" class="text-left width-control" ref="responseContainer"></div>
                        </template>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Message from 'primevue/message'
import Card from 'primevue/card'
import { marked } from 'marked';
import { onAskQuestionButtonClick, insertFormula } from '../methods/askgpt.js';
import { gptResponse } from '../methods/store.js';
import DOMPurify from 'dompurify';

const question = ref('');
const includeContext = ref();
const renderedHtml = ref(''); // Use a separate ref for the rendered HTML
const responseContainer = ref(null);

const purifyConfig = {
    ADD_ATTR: ['onclick', 'id', 'data-target'],
    ADD_TAGS: ['button'],
    FORBID_TAGS: ['style', 'script'],
    FORBID_ATTR: ['style', 'onerror', 'onload'],
    ALLOW_DATA_ATTR: true,
    // If you're using custom elements, you may need to add them here
    ADD_URI_SAFE_ATTR: ['onclick']
};

// Set up custom renderer for marked
onMounted(() => {
    // Create a new renderer
    const renderer = new marked.Renderer();

    // Counter to create unique IDs for each code block
    let codeBlockCounter = 0;

    // Override the code block renderer
    renderer.code = function (code) {
        // Ensure code is a string
        console.log(code)
        const codeStr = String(code.text);
        const displayLanguage = 'Excel Formula';
        const cleanedCode = codeStr.trim();
        const blockId = `excel-formula-${codeBlockCounter++}`;

        return `<div class="formula-block-wrapper">
                  <div class="formula-header">
                    <span class="formula-type">${displayLanguage}</span>
                    <button class="copy-button" onclick="copyFormulaToClipboard('${blockId}')">
                      <i class="pi pi-copy"></i> Copy
                    </button>
                    <button class="insert-button" onclick="insertFormula('${blockId}')">                        
                      <i class="pi pi-arrow-right"></i> Insert
                    </button>
                  </div>
                  <pre><code id="${blockId}" class="excel-formula">${cleanedCode}</code></pre>
                </div>`;
    };

    // Set the renderer options
    marked.setOptions({
        renderer: renderer,
        gfm: true,
        breaks: true,
        highlight: function (code) {
            // Ensure code is a string
            const codeStr = String(code);
            // Format Excel formula for better readability
            return codeStr
                .replace(/=/g, '<span class="excel-equals">=</span>')
                .replace(/\b(SUM|AVERAGE|COUNT|IF|VLOOKUP|HLOOKUP|INDEX|MATCH|CONCATENATE|LEN|LEFT|RIGHT|MID)\b/gi,
                    '<span class="excel-function">$1</span>')
                .replace(/[,;]/g, '<span class="excel-separator">$&</span>');
        }
    });


    // Configure DOMPurify hooks
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // If the node is a button with data-target attribute
        if (node.tagName === 'BUTTON' && node.hasAttribute('data-target')) {
            node.setAttribute('type', 'button');

            if (node.classList.contains('copy-button')) {
                node.setAttribute('onclick', `copyFormulaToClipboard('${node.getAttribute('data-target')}')`);
            } else if (node.classList.contains('insert-button')) {
                node.setAttribute('onclick', `insertFormula('${node.getAttribute('data-target')}')`);
            }
        }
    });


    // Add global function for copying formula using Clipboard API
    window.copyFormulaToClipboard = async function (elementId) {
        const codeElement = document.getElementById(elementId);
        if (!codeElement) return;

        const textToCopy = codeElement.textContent || codeElement.innerText;
        const button = document.querySelector(`#${elementId}`).closest('.formula-block-wrapper').querySelector('.copy-button');
        const originalText = button.innerHTML;

        try {
            await navigator.clipboard.writeText(textToCopy);

            // Show success feedback
            button.innerHTML = '<i class="pi pi-check"></i> Copied!';

            // Reset button text after 2 seconds
            setTimeout(() => {
                button.innerHTML = originalText;
            }, 2000);
        } catch (err) {
            console.error('Failed to copy formula: ', err);

            // Show error feedback
            button.innerHTML = '<i class="pi pi-times"></i> Failed!';
            setTimeout(() => {
                button.innerHTML = originalText;
            }, 2000);
        }
    };

    // Make insertFormula globally available
    window.insertFormula = function (elementId) {
        const codeElement = document.getElementById(elementId);
        if (!codeElement) return;

        const formula = codeElement.textContent || codeElement.innerText;
        insertFormula(formula);

        const button = document.querySelector(`#${elementId}`).closest('.formula-block-wrapper').querySelector('.insert-button');
        const originalText = button.innerHTML;

        // Show feedback
        button.innerHTML = '<i class="pi pi-check"></i> Inserted!';

        // Reset button text after 2 seconds
        setTimeout(() => {
            button.innerHTML = originalText;
        }, 2000);
    };
});



// Watch for changes to information in the data store and update the UI
watch(() => gptResponse.value, (newValue) => {
    console.log("GPT response changed:", newValue); // Debug logging

    if (newValue) {
        // Handle different possible response formats
        let answerText = '';

        if (typeof newValue === 'string') {
            answerText = newValue;
        } else if (newValue && typeof newValue === 'object') {
            // Try to extract the answer from various possible properties
            if (newValue.answer) {
                answerText = typeof newValue.answer === 'string' ? newValue.answer : JSON.stringify(newValue.answer);
            } else if (newValue.response) {
                answerText = typeof newValue.response === 'string' ? newValue.response : JSON.stringify(newValue.response);
            } else if (newValue.text) {
                answerText = typeof newValue.text === 'string' ? newValue.text : JSON.stringify(newValue.text);
            } else {
                // If we can't find a specific property, stringify the whole object
                answerText = JSON.stringify(newValue, null, 2);
            }
        }
        console.log("Processing answer text:", answerText);
        // First convert markdown to HTML
        const markedHtml = marked.parse(answerText);
        // Then sanitize the HTML
        renderedHtml.value = DOMPurify.sanitize(markedHtml, purifyConfig);

        // After the DOM updates, attach event listeners to copy buttons
        /*
        nextTick(() => {
            attachButtonListeners();
            
        });*/
    } else {
        renderedHtml.value = 'Awaiting Response';
    }
}, { deep: true });

// Function to attach event listeners to copy buttons after the DOM updates
/*
function attachButtonListeners() {
    if (!responseContainer.value) return;
    const copyButtons = responseContainer.value.querySelectorAll('.copy-button');
    copyButtons.forEach(button => {
        button.addEventListener('click', function () {
            const targetId = this.getAttribute('data-target');
            window.copyFormulaToClipboard(targetId, this);
        });
    });
    const insertButtons = responseContainer.value.querySelectorAll('.insert-button');
    insertButtons.forEach(button => {
        button.addEventListener('click', function () {
            const targetId = this.getAttribute('data-target');
            insertFormula(targetId, this);
        });
    });
}
*/
</script>

<style scoped>
/* If you want to target the inner content area of the card */
.custom-card ::v-deep .p-card-body {
    background-color: #fafafa;
    color: #000000;
    padding: 7px;
    max-width: 100%;
    border: 3px;
    border-color: #3f51b5;
    border-style: solid;
    border-radius: 10px;
    margin: 7px;
}

:deep code {
    font-family: monospace;
    background-color: #dbdbdb;
    display: inline-flex;
    width: 100%;
    padding: 3px;
}

.text-left {
    text-align: left;
}

:deep .formula-block-wrapper {
    margin: 1rem 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #b3c0ff;
}

:deep .formula-header {
    background-color: #2b579a;
    /* Excel blue */
    color: #ffffff;
    padding: 0.5rem 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Copy button styling - only included once */
:deep .copy-button {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 4px;
    color: white;
    padding: 2px 4px;
    font-size: 0.6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: background-color 0.2s;
}

:deep .copy-button:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

:deep .copy-button:active {
    background-color: rgba(255, 255, 255, 0.4);
}

:deep .copy-button i {
    font-size: 0.9rem;
}

/* Insert button styling */
:deep .insert-button {
    background-color: rgba(72, 199, 142, 0.2);
    border: none;
    border-radius: 4px;
    color: white;
    padding: 2px 6px;
    font-size: 0.6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    transition: all 0.2s;
    margin-left: 6px;
}

:deep .insert-button:hover {
    background-color: rgba(72, 199, 142, 0.4);
    transform: scale(1.05);
}

:deep .insert-button:active {
    background-color: rgba(72, 199, 142, 0.6);
    transform: scale(0.98);
}

:deep .insert-button i {
    font-size: 0.9rem;
}
</style>