<template>
    <div class="main m-1 text-center text-100 text-xl">
        <p align="center" class="text-100 text-md">Carollo DocAI Optical <br />Character Recognition</p>
        <p class="center-text text-100 text-sm">Provide a scanned / handwritten PDF file <br /> to parse text and
            tables</p>
        <p class="pb-1 text-sm">
            <FileUpload ref="fileSelected" mode="basic" name="files[]" :maxFileSize="104800000" @select="onFileSelect"
                accept="application/pdf" /><br />
            <span class="text-sm text-100 underline" @click="reset">Reset</span>
        </p>
        <Message severity="warn" :closable="true">
            <strong>This feature queries Microsoft's online Document Intelligence Service. Do not include
                sensitive information.</strong>
        </Message>
        <div class="align-items-center text-lg text-primary align-items-center pt-3">
            Features to Analyze:
        </div>
        <div class="formgrid grid px-2 pt-2 text-sm text-items-left">

            <div class="field-checkbox col-12 text-left" v-for="option in optionsUI" :key="option">
                <Checkbox v-model="option.value" :inputId="option.name" binary :value="option.value" />
                <label :for="option.name"> {{ option.label }} </label>
            </div>
            <div class="field col-6 text-left" v-if="optionsUI[2].value">
                <InputNumber v-model="headerRows" suffix=" rows" size="small" inputId="headerRows" showButtons fluid
                    :step="1" />
            </div>
            <div class="field col-6 text-left" v-if="optionsUI[2].value">
                <label for="headerRows"> Number of Header Rows </label>
            </div>
        </div>
        <Button :label="aiOCR.processing ? 'Processing...' : 'Upload & Analyze'" id="proceed"
            :icon="aiOCR.processing ? 'pi pi-spin pi-spinner' : 'pi pi-send'" @click="goBabyGo(uploadedFile)"
            class="w-full" :disabled="!aiOCR.ready || aiOCR.processing" raised />

        <Card class="custom-card" v-if="aiOCR.response">
            <template #title>
                <p class="main m-1 text-center text-100 text-md">Progress Log</p>
            </template>
            <template #content>
                <p class="text-sm">{{ aiOCR.response }}</p>
            </template>
        </Card>
    </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, watch } from 'vue'
import Button from 'primevue/button';
import Message from 'primevue/message'
import Card from 'primevue/card'
import { processTableData, createGenericWorksheetFromData } from '../methods/AIOCR.js'
import { uploadFile } from '@/methods/uploadAPI.js';
import FileUpload from 'primevue/fileupload';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import { aiOCR } from '../methods/store.js';

let data;

const optionsUI = ref([
    {
        name: "checkmarks",
        label: "Include Checkmarks / Selections",
        value: false
    },
    {
        name: "oneTable",
        label: "Put all found tables into one worksheet",
        value: false
    },
    {
        name: "headers",
        label: "Dataset Contains Headers",
        value: false
    },
])

const headerRows = ref(1)

const fileSelected = ref(null)
var uploadedFile = null

function onFileSelect(event) {
    //one file only at a time for now.
    if (event.files.length > 0) {
        uploadedFile = event.files[0];
        aiOCR.ready = true
        aiOCR.response += `Ready to Uploaded and Parse file: ${uploadedFile.name}\n`;
    }
}

const fullURL =
    "https://carollo-doc-ai-excel-asbteqbehsewavdy.eastus2-01.azurewebsites.net/api/upandana?";

var fileType = "application/pdf";

async function goBabyGo(file) {

    aiOCR.processing = true;
    data = await uploadFile(file, fullURL, aiOCR, fileType);

    try { (data.tables.length) } catch (error) {
        console.log("No tables found in the document." + error);
        aiOCR.response += "\nNo tables found in the document.";
        return;
    }
    aiOCR.response += "\n processing tablular data... \n"

    const options = {
        keepCheckmarks: optionsUI.value[0].value,
        oneTable: optionsUI.value[1].value,
        headers: optionsUI.value[2].value,
        headerRowCount: headerRows.value
    }

    try {
        let allRows = [];
        if (!options.oneTable) {
            // Process each table separately
            for (let i = 0; i < data.tables.length; i++) {
                const table = data.tables[i];
                const rows = processTableData(table, options);

                if (rows.length > 0) {
                    await createGenericWorksheetFromData(rows, options);
                    aiOCR.response += `\nAdded ${rows.length} rows to a new worksheet... \n`;
                }
            }
        } else {
            // Combine all tables into one dataset
            for (let table of data.tables) {
                const rows = processTableData(table, options);
                allRows = allRows.concat(rows);
            }

            if (allRows.length > 0) {
                await createGenericWorksheetFromData(allRows, options);
                aiOCR.response += `\nAdded ${allRows.length} rows to a new worksheet... \n`;
            }
        }
        aiOCR.response += `\nUpload and Analysis Successful.Processed ${data.tables.length} tables. \n`;
    } catch (error) {
        console.error("Error processing tables:", error);
        aiOCR.response += "\nError processing tables: " + error.message;
    } finally {
        aiOCR.processing = false;
        if (fileSelected.value) {
            fileSelected.value.clear();
        }
    }
}

function reset() {
    fileSelected.value.clear();
    aiOCR.response = "";
}


</script>
<style scoped>
.note {
    width: 200px;
    margin: 10px;
    font-size: 1.1em;
    color: #333;
    text-align: justify;
}


.response {
    display: block;
    color: blue;
    height: 300px;
    overflow-y: scroll;
    padding: 10px;
    margin: 10px;
    border: 1px solid black;
    border-radius: 5px;
    width: 250px;
}

.response:has(.results:empty) {
    display: none;
}

.p-fileupload-file-thumbnail {
    display: none;
}
</style>
