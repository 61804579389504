import { createRouter, createMemoryHistory } from "vue-router";
import Tools from "../views/Tools.vue";
import UnitConvert from "../views/UnitConvert.vue";
import FillMagic from "../views/FillMagic.vue";
import CopyPasteTools from "../views/CopyPasteTools.vue";
import AskGPT from "../views/AskGPT.vue";
import AIOCR from "../views/AIOCR.vue";

const routes = [
  {
    path: "/",
    name: "Home Tools",
    component: Tools,
    alias: ["/taskpane", "/taskpane.html"],
  },
  {
    path: "/UnitConvert",
    name: "UnitConvert",
    component: UnitConvert,
  },
  {
    path: "/FillMagic",
    name: "FillMagic",
    component: FillMagic,
  },
  {
    path: "/CopyPaste",
    name: "CopyPaste",
    component: CopyPasteTools,
  },
  {
    path: "/AskGPT",
    name: "AskGPT",
    component: AskGPT,
  },
  {
    path: "/AIOCR",
    name: "AI OCR",
    component: AIOCR,
  },
];
const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

export default router;
